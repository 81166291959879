exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accommodation-js": () => import("./../../../src/pages/accommodation.js" /* webpackChunkName: "component---src-pages-accommodation-js" */),
  "component---src-pages-call-for-abstracts-js": () => import("./../../../src/pages/call-for-abstracts.js" /* webpackChunkName: "component---src-pages-call-for-abstracts-js" */),
  "component---src-pages-conference-venue-js": () => import("./../../../src/pages/conference-venue.js" /* webpackChunkName: "component---src-pages-conference-venue-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-discover-auckland-js": () => import("./../../../src/pages/discover-auckland.js" /* webpackChunkName: "component---src-pages-discover-auckland-js" */),
  "component---src-pages-explore-new-zealand-js": () => import("./../../../src/pages/explore-new-zealand.js" /* webpackChunkName: "component---src-pages-explore-new-zealand-js" */),
  "component---src-pages-host-and-committee-js": () => import("./../../../src/pages/host-and-committee.js" /* webpackChunkName: "component---src-pages-host-and-committee-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-news-js": () => import("./../../../src/pages/latest-news.js" /* webpackChunkName: "component---src-pages-latest-news-js" */),
  "component---src-pages-our-sponsors-js": () => import("./../../../src/pages/our-sponsors.js" /* webpackChunkName: "component---src-pages-our-sponsors-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-registration-old-js": () => import("./../../../src/pages/registration-old.js" /* webpackChunkName: "component---src-pages-registration-old-js" */),
  "component---src-pages-sponsorship-opportunities-js": () => import("./../../../src/pages/sponsorship-opportunities.js" /* webpackChunkName: "component---src-pages-sponsorship-opportunities-js" */),
  "component---src-pages-tiaki-promise-js": () => import("./../../../src/pages/tiaki-promise.js" /* webpackChunkName: "component---src-pages-tiaki-promise-js" */),
  "component---src-pages-tours-js": () => import("./../../../src/pages/tours.js" /* webpackChunkName: "component---src-pages-tours-js" */),
  "component---src-pages-visa-information-js": () => import("./../../../src/pages/visa-information.js" /* webpackChunkName: "component---src-pages-visa-information-js" */)
}

