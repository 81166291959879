const pageData = {
    "/": {
      backgroundImage: "/images/Beaches-of-Auckland.jpg",
      heading: "Welcome to TIAFT 2025",
      description: "The 62nd Annual Meeting of the International Association of Forensic Toxicologists",
    },
    "/host-and-committee/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Host & Committee",
      description: "Meet the hosts and committee members.",
    },
    "/accommodation/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Accommodation",
      description: "Find the best places to stay during your event.",
    },
    "/program/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Program Overview",
      description: "Get an overview of the five day program of TIAFT 2025.",
    },
    "/destinations/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Destinations",
      description: "Explore various destinations for your next event.",
    },
    "/latest-news/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Latest News",
      description: "Stay updated with the latest news and updates.",
    },
    "/contact/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Contact Us",
      description: "For all enquiries, please contact the TIAFT25 Meeting Secretariat.",
    },
    "/privacy-policy/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Privacy Policy",
      description: "Read our Privacy Policy to understand how we handle your personal information.",
    },
    "/conference-venue/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Conference Venue",
      description: "Discover the venue for TIAFT 2025.",
    },
    "/discover-auckland/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Discover Auckland",
      description: "Find out what makes Auckland a great destination.",
    },
    "/explore-new-zealand/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Explore New Zealand",
      description: "Learn more about the beautiful country of New Zealand.",
    },
    "/registration/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Registration",
      description: "Register now for TIAFT 2025.",
    },
    "/tiaki-promise/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Tiaki Promise",
      description: "Learn about New Zealand's Tiaki Promise.",
    },
    "/sponsorship-opportunities/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Sponsorship Opportunities",
      description: "Find our opportunities to become a sponsor of TIAFT 2025.",
    },
    "/visa-information/": {
      backgroundImage: "/images/airplane.jpg",
      heading: "Visa Information",
      description: "Learn about visa requirements to attend TIAFT 2025.",
    },
    "/our-sponsors/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Our Sponsors",
      description: "Thank you for our sponsors who support TIAFT 2025.",
    },
    "/call-for-abstracts/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Call for Abstracts",
      description: "Submit your abstract and contribute to TIAFT 2025.",
    },
    "/accommodation/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Accommodation",
      description: "Find the best accommodation options for The 62nd Annual Meeting of the International Association of Forensic Toxicologists (TIAFT) in Auckland, New Zealand.",
    },
    "/tours/": {
      // backgroundImage: "/images/color-book.jpg",
      heading: "Tours",
      description: "Explore the best of Auckland and beyond with our exclusive TIAFT 2025 tours, offering delegates the chance to discover New Zealand's stunning landscapes, rich culture and world-renowned wine regions.",
    },
  };
  

  export const getPageData = (path) => {
    const defaultData = {
      backgroundImage: "/images/test-tubes.jpg",
    };
  
    const data = pageData[path] || {};
  
    return {
      ...defaultData,
      ...data,
    };
  };
  